import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import BlogReducer from 'Store/Blog/Blog.reducer';
import CategoryReducer from 'Store/Category/Category.reducer';
import ProductReducer from 'Store/Product/Product.reducer';
import { setPageType } from 'Store/UrlRewrites/UrlRewrites.action';
import componentLoader from 'Util/componentLoader';
import { withReducers } from 'Util/DynamicReducer';

import {
    TYPE_BLOG_CATEGORY,
    TYPE_BLOG_POST,
    TYPE_BLOG_TAG,
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT
} from './UrlRewrites.config';
/** @namespace Bodypwa/Route/UrlRewrites/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

export const UrlRewritesDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/UrlRewrites/UrlRewrites.dispatcher'
), 2);

export const NoMatchDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NoMatch/NoMatch.dispatcher'
), 2);

/** @namespace Bodypwa/Route/UrlRewrites/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestUrlRewrite: (urlParam) => {
        UrlRewritesDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch, { urlParam })
        );
    },
    setPageType: (type) => dispatch(setPageType(type))
});

/** @namespace Bodypwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    componentDidUpdate() {
        const { isLoading } = this.props;

        /**
         * If the latest requested URL rewrite is not related
         * to the current location, and the URL rewrites are not loading
         * request new URL rewrite.
         */
        if (this.getIsLoading() && !isLoading) {
            this.requestUrlRewrite();
        }

        /**
         * Make sure that PDP & PLP url don't have "/" in the end
         */
        this.redirectToCorrectUrl();
    }

    redirectToCorrectUrl() {
        const { setPageType } = this.props;

        const type = this.getType();

        setPageType(type);
    }

    getTypeSpecificProps() {
        const {
            urlRewrite: {
                id,
                sku
            }
        } = this.props;

        const isLoading = this.getIsLoading();

        switch (this.getType()) {
        case TYPE_BLOG_POST:
            if (isLoading) {
                return { isOnlyPlaceholder: true };
            }

            return { id };
        case TYPE_BLOG_CATEGORY:
        case TYPE_BLOG_TAG:
            if (isLoading) {
                return { isOnlyPlaceholder: true };
            }

            return { id };
        case TYPE_PRODUCT:
            /**
             * In case we are not yet sure what product ID it is:
             * - check if there is a hint in browser history
             * - fallback to none
             */
            if (isLoading) {
                const product = history?.state?.state?.product;

                if (product) {
                    const { sku: historySKU, id } = product;

                    return { productSKU: historySKU, id };
                }

                return {};
            }

            return { productSKU: sku, id };
        case TYPE_CMS_PAGE:
            if (isLoading) {
                return { isOnlyPlaceholder: true };
            }

            return { pageIds: id };
        case TYPE_CATEGORY:
            /**
             * In case we are not yet sure what category ID it is:
             * - check if there is a hint in browser history
             * - fallback to none
             */
            if (isLoading) {
                const category = history?.state?.state?.category;

                if (category && category !== true) {
                    return { categoryIds: category };
                }

                return {};
            }

            return { categoryIds: id };
        case TYPE_NOTFOUND:
        default:
            return {};
        }
    }

    requestUrlRewrite() {
        const { requestUrlRewrite } = this.props;

        return requestUrlRewrite(location.pathname);
    }
}

export default withReducers({
    BlogReducer,
    CategoryReducer,
    ProductReducer
})(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UrlRewritesContainer)
);
